import React from 'react';
import Countdown from 'react-countdown';
import Birthday from './birthday/birthday';

function getTextFor(type, value) {
  if (value === 0) {
    return ''
  }
  if (value > 1) {
    return type
  }
  switch(type) {
    case 'days':
      return 'day'
    case 'hours':
      return 'hour'
    case 'minutes':
      return 'minute'
    case 'seconds':
      return 'second'
  }
}

const Timer = ({ onFirstComplete }) => {
  return <Countdown
    date={1630261800000}
    // date={1620261800000}
    renderer={({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Birthday onFirstComplete={onFirstComplete} />;
      } else {
        // Render a countdown
        return <span>{days !== 0 ? days : ''} {getTextFor('days', days)} {hours !== 0 ? hours : ''} {getTextFor('hours', hours)} {minutes !== 0 ? minutes : ''} {getTextFor('minutes', minutes)} {seconds !== 0 ? seconds : ''} {getTextFor('seconds', seconds)}</span>;
      }
    }}
  />
}

export default Timer