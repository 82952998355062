import React from 'react'
import { Dropdown, IconButton, Icon } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import '../App.css';
// TODO: This is missing functionality for sub-menu here from SUI core examples.
// The "Publish To Web" item should contain a sub-menu.

const DropdownExampleDropdown = ({ items, onPress }) => (
  <div className="App-dropdown">
    <Dropdown
      placement="leftStart"
      renderTitle={() => {
        return <IconButton appearance="subtle" icon={<Icon icon="bars" />} circle />;
      }}
    >
      {items.map((item, index) => {
        return <Dropdown.Item onSelect={() => onPress(index)} key={item}>
        {item}
      </Dropdown.Item>
      })}
    </Dropdown>
  </div>
)

export default DropdownExampleDropdown