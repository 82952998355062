import React from "react";
import './moon.css';

const Moon = () => (
  <div>
    <div className='moon'>
      <div className='love'>
        <div className="love__heart love__heart-1">
        </div>  
        <div className="love__heart love__heart-2">
        </div> 
        <div className="love__heart love__heart-3">
        </div> 
      </div>
      <div className="moon__container">
        <div className="moon__crater moon__crater--small moon__crater--3">
        </div>
        <div className="moon__crater moon__crater--medium moon__crater--1">
        </div>
        <div className="moon__crater moon__crater--big moon__crater--5">
        </div>
        <div className="moon__face">
          <div className="moon__eye moon__eye--left">
          </div>
          <div className="moon__eye moon__eye--right">
          </div>
          <div className="moon__cheek moon__cheek--left">
          </div>
          <div className="moon__cheek moon__cheek--right">
          </div>
          <div className="moon__smile">
          </div>
        </div> 
      </div>
      <div className="chat_item chat_item--1">
      Hello Nivedita!
      </div>
      <div className="chat_item chat_item--2">
      <p> I'm your mooooooooon!</p>
      </div>
      <div className="chat_item chat_item--3">
      <p>I'm here to lookout for you</p>
      </div>
      {/* <div className="chat_item chat_item--4">
      <p>I'll make you happy when you feel sad</p>
      </div> */}
      <div className="chat_item chat_item--5">
      <p>I'll cheer for you all the time</p>
      </div>
      <div className="chat_item chat_item--6">
      <p> I beeleaf in you</p>
      </div>
    </div>  
  </div> 
);

export default Moon;