import React from "react";
import Typist from 'react-typist';

const style = { 
  fontSize: 40
}
const Messages = () => (
  <Typist cursor={{
    show: false,
    blink: true,
    element: '|',
    hideWhenDone: false,
    hideWhenDoneDelay: 1000,
  }}>
    <span style={style}> Hello Nivedita! </span>
    <Typist.Backspace count={17} delay={1000} />
    <span style={style}> You are a sweetie pie </span>
    <Typist.Backspace count={23} delay={1000} />
    <span style={style}> You make me blush </span>
    <Typist.Backspace count={19} delay={1000} />
    <span style={style}> And you make me laugh </span>
    <Typist.Backspace count={23} delay={1000} />
    <span style={style}> You bring me up when i'm down </span>
    <Typist.Backspace count={31} delay={1000} />
    <span style={style}> You brighten my days when they're dark </span>
    <Typist.Backspace count={40} delay={1000} />
    <span style={style}> You never lose hope </span>
    <Typist.Backspace count={21} delay={1000} />
    <span style={style}> You are a fairy princess </span>
    <Typist.Backspace count={26} delay={1000} />
    <span style={style}> You are mine </span>
    <Typist.Backspace count={14} delay={1000} />
    <span style={style}> I love you so much </span>
    <Typist.Backspace count={20} delay={1000} />
    <span style={style}> And I always will </span>
    <Typist.Backspace count={19} delay={1000} />
    <span style={style}> Yours, ♡ </span>
  </Typist>
);

export default Messages;
