import './newyear.css';
import Countdown from 'react-countdown';
import sample from '../../videos/ny2023.mp4';

const NewYear = () => {

  return (
    <video id="background-video" autoPlay playsInline loop muted>
        <source src={sample} type='video/mp4' />
    </video>  
  )
  
}

function getTextFor(type, value) {
    if (value === 0) {
      return ''
    }
    if (value > 1) {
      return type
    }
    switch(type) {
      case 'days':
        return 'day'
      case 'hours':
        return 'hour'
      case 'minutes':
        return 'minute'
      case 'seconds':
        return 'second'
    }
}

const Timer = () => {
    return <Countdown
      date={1672511401000}
    //   date={1661663881000}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <NewYear />;
        } else {
          // Render a countdown
          return <span className='App-content newyear-2023-countdown'>{days !== 0 ? days : ''} {getTextFor('days', days)} {hours !== 0 ? hours : ''} {getTextFor('hours', hours)} {minutes !== 0 ? minutes : ''} {getTextFor('minutes', minutes)} {seconds !== 0 ? seconds : ''} {getTextFor('seconds', seconds)}</span>;
        }
      }}
    />
  }

export default Timer
