import React from "react";
import TextLoop from "react-text-loop";
import Typist from 'react-typist';

const style = { 
  fontSize: 40
}
const Messages = () => (
  <h4>
    {/* <TextLoop interval="2000" springConfig={{ stiffness: 180, damping: 8 }}>
        <span>1</span>
        <span>2</span>  
        <span>3</span>
        <span>4</span>
        <span>5</span> 
        <span>6 years!</span>
    </TextLoop> */}
    <Typist cursor={{
        show: false,
        blink: true,
        element: '|',
        hideWhenDone: false,
        hideWhenDoneDelay: 1000,
    }}>
        <span>1</span>
        <Typist.Backspace count={1} delay={1500} />
        {/* <Typist.Delay ms={500} /> */}
        <span>2</span>
        <Typist.Backspace count={1} delay={1500} />
        {/* <Typist.Delay ms={500} /> */}
        <span>3</span>
        <Typist.Backspace count={1} delay={1500} />
        {/* <Typist.Delay ms={500} /> */}
        <span>4</span>
        <Typist.Backspace count={1} delay={1500} />
        {/* <Typist.Delay ms={500} /> */}
        <span>5</span>
        <Typist.Backspace count={1} delay={1500} />
        {/* <Typist.Delay ms={500} /> */}
        <span>6 years!</span>
    </Typist>
  </h4>
);

export default Messages;
