import React, { useEffect, useState } from 'react';
import Typist from 'react-typist';
import "./birthday.css";
import Particles from "../particles";
import useCheckMobileScreen from '../../utils/checkDevice';

const style = { 
  // fontSize: 40
  // textAlign: 'center',
}



function FirstRound(onTypingDone) {
  return (
    <Typist cursor={{
      show: false,
      blink: true,
      element: '|',
      hideWhenDone: false,
      hideWhenDoneDelay: 1000,
    }} onTypingDone={onTypingDone}>
      <span style={style}> i just wanted to say </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> despite everything, i love you </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> and i miss you </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> and i think of you a countless times </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> and i hope for your happiness </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> i hope your days go well </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> not just today, but every coming day </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> even if today is sad, </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> i hope you be excited for tomorrow </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> anyways, </span>
      <Typist.Delay ms={1000} />
      <br />
      <span style={style}> happy birthday to you!  </span>
      <Typist.Delay ms={5000} />
    </Typist>
  )
}

function SecondRound(isMobile) {
  
  return <div>
    <h1>happy b1rth9ay</h1>
    {isMobile ? null : <br />}
    <h6>to cute nivi</h6>
    </div>
}

function Birthday({ onFirstComplete }) {

  const [firstRoundDone, setFirstRoundDone] = useState(false)
  const [secondRoundDone, setSecondRoundDone] = useState(false)

  const isMobile = useCheckMobileScreen()

  function onFirstRoundComplete() {
    setFirstRoundDone(true)
    onFirstComplete(true)
  }

  function onSecondRoundComplete() {
    setSecondRoundDone(true)
  }


  return firstRoundDone ? SecondRound(isMobile) : FirstRound(onFirstRoundComplete)
  return <span>Happy Birthday!</span>
}

export default Birthday;