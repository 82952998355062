import mooninblack from '../images/nivi.svg';

export default {
    autoPlay: true,
    background: {
        color: {
            value: "#000000"
        },
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover",
        opacity: 1
    },
    fullScreen: {
        enable: true,
        zIndex: 1,
    },
    fpsLimit: 30,
    duration: 0,
    interactivity: {
        detectsOn: "window",
        events: {
            onClick: {
                enable: true,
                mode: "push"
            },
            resize: true
        }
    },
    particles: {
        number: {
            value: 0,
        },
        color: {
            value: [
                "#ffffff"
            ],
        },
        shadow: {
            blur: 0,
            enable: false
        },
        shape: {
            type: "circle",
        },
        opacity: {
            value: 1,
            animation: {
                enable: true,
                minimumValue: 0,
                speed: 0.5,
                startValue: "max",
                destroy: "min",
            },
        },
        size: {
            value: 1,
            random: {
                enable: true,
                minimumValue: 1
            },
            animation: {
                enable: false
            }
        },
        links: {
            enable: true,
            blink: false,
            color: {
                value: "random"
            },
            consent: false,
            distance: 40,
            frequency: 1,
            opacity: 1,
            shadow: {
                blur: 5,
                color: {
                    value: "#00ff00"
                },
                enable: false
            },
            triangles: {
                enable: false
            },
            width: 1,
            warp: false
        },
        life: {
            duration: {
                sync: false,
                value: 0,
            },
            count: 0,
        },
        move: {
            angle: {
                value: 90,
                offset: 0,
            },
            drift: 0,
            enable: true,
            gravity: {
                enable: false,
                acceleration: 9.81,
            },
            path: {
                clamp: true,
                delay: {
                    value: 0
                },
                enable: false
            },
            speed: 1,
            decay: 0,
            direction: "none",
            random: false,
            straight: false,
            outModes: {
                default: "bounce",
                bottom: "bounce",
                left: "bounce",
                right: "bounce",
                top: "bounce"
            },
            spin: {
                enable: false
            },
            trail: {
                enable: false
            },
            vibrate: false,
            warp: false
        },
        number: {
            density: {
                enable: false
            },
            limit: 0,
            value: 200
        },
        opacity: {
            random: {
                enable: false
            },
            value: {
                min: 0.05,
                max: 0.4
            },
            animation: {
                count: 0,
                enable: true,
                speed: 2,
                sync: false,
                destroy: "none",
                startValue: "random",
                minimumValue: 0.05
            }
        },
        orbit: {
            enable: false
        },
        reduceDuplicates: false,
        repulse: {
            enabled: false
        },
        rotate: {
            random: {
                enable: false
            },
            value: 0,
            direction: "clockwise",
            animation: {
                enable: false,
                speed: 0,
            },
            path: false
        },
        stroke: {
            width: 0
        },
        tilt: {
            random: {
                enable: false,
                minimumValue: 0
            },
            direction: "clockwise",
            enable: false,
            value: 0,
            animation: {
                enable: false,
                speed: 0,
                sync: false
            },
        },
        roll: {
            darken: {
                enable: false,
                value: 0,
            },
            enable: false,
            speed: 25,
            mode: "vertical"
        },
        twinkle: {
            lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1
            },
            particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1
            }
        },
        wobble: {
            distance: 5,
            enable: false,
            speed: 50,
        },
        zIndex: {
            random: {
                enable: false,
                minimumValue: 0
            },
            value: 0,
            opacityRate: 1,
            sizeRate: 1,
            velocityRate: 1
        },
    },
    detectRetina: true,
    motion: {
        disable: false,
        reduce: {
            factor: 4,
            value: true
        }
    },
    // emitters: {
    //     startCount: actualOptions.count,
    //     position: actualOptions.position,
    //     size: {
    //         width: 0,
    //         height: 0,
    //     },
    //     rate: {
    //         delay: 0,
    //         quantity: 0,
    //     },
    //     life: {
    //         duration: 0.1,
    //         count: 1,
    //     },
    // },
    pauseOnBlur: true,
    pauseOnOutsideViewport: true,
    zLayers: 100,
    polygon: {
        draw: {
            enable: false,
            stroke: {
                color: {
                    value: "#fff"
                },
                width: 0.5,
                opacity: 0.2
            }
        },
        enable: true,
        inline: {
            arrangement: "equidistant"
        },
        move: {
            radius: 10,
            type: "path"
        },
        scale: 1,
        type: "inline",
        url: mooninblack,
        // image: "images/red-heart.svg",
        position: {
            x: 30,
            y: 10
        }
    }
}