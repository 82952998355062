import React from 'react';
import sample from '../../videos/besafe.mp4';
import "./besafe.css";

function BeSafe() {
    return (
        <video id="background-video" autoPlay playsInline loop muted>
            <source src={sample} type='video/mp4' />
        </video>  
    )
}

export default BeSafe