// Based on https://vincentgarreau.com/particles.js/
import balloons1 from '../images/balloons_1.png';
import balloons2 from '../images/balloons_2.png';

export default {
    "particles": {
      "number": {
        "value": 10,
        "density": {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#e6437b"
      },
      "shape": {
        "type": "images",
        "images": [
            {src: balloons1, height: 20, width: 20},
            {src: balloons2, height: 20, width: 20},
        ]
      },
      "opacity": {
        "value": 0.5,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 10,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 40,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": false,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.5,
        "direction": "top",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "retina_detect": true
  }