import React from "react";
import TextLoop from "react-text-loop";

const style = { 
  fontSize: 40
}
const Messages = () => (
  <h4>
    four years of
    {" "}
    <TextLoop>
        <span>love</span>
        <span>talking</span>  
        <span>happiness</span>
        <span>annoying each other</span>
        <span>getting to know each other</span> 
        <span>being there for each other</span>
        <span>caring for each other</span> 
        <span>holding on</span>
        <span>friendship</span>
    </TextLoop>
  </h4>
);

export default Messages;
