import Typist from 'react-typist';

const style = { 
    // fontSize: 40
    // textAlign: 'center',
}

const styleSmall = {
    fontSize: 15
}

function FirstMeet(onTypingDone) {
    return (
        <Typist cursor={{
            show: false,
            blink: true,
            element: '|',
            hideWhenDone: false,
            hideWhenDoneDelay: 1000,
        }} onTypingDone={onTypingDone}>
            <span style={style}> we met for the first time :) </span>
            <Typist.Delay ms={1000} />
            <br />
            <span style={style}> although we both were shy :( </span>
            <Typist.Delay ms={1000} />
            <br />
            <span style={style}> i'm glad we were happy :) </span>
            <Typist.Delay ms={1000} />
            <br />
            <br />
            <br />
            <br />
            <span style={styleSmall}> P.S. the cute person had this donut :) </span>
        </Typist>
    )
}

export default FirstMeet