import React from 'react';
import sample from '../../videos/sixyears.mp4';
import "./sixyears_background.css";

function SixYearsBackground() {
    return (
        <video id="background-video" autoPlay playsInline loop muted>
            <source src={sample} type='video/mp4' />
        </video>  
    )
}

export default SixYearsBackground