import React from 'react';
import sample from '../../videos/bday2023.mp4';
import "./birthday.css";
import Countdown from 'react-countdown';
import Typist from 'react-typist';

function Birthday2023() {
    return (
        <video id="background-video" autoPlay playsInline loop muted>
            <source src={sample} type='video/mp4' />
        </video>  
    )
}

function getTextFor(type, value) {
    if (value === 0) {
      return ''
    }
    if (value > 1) {
      return type
    }
    switch(type) {
      case 'days':
        return 'day'
      case 'hours':
        return 'hour'
      case 'minutes':
        return 'minute'
      case 'seconds':
        return 'second'
    }
  }

  function Content() {
    return (
        <>
        <Birthday2023 />
        <span className='App-content birthday-2022-title'>
            <Typist cursor={{
                show: false,
                blink: true,
                element: '|',
                hideWhenDone: false,
                hideWhenDoneDelay: 1000,
            }}>
                <span> happy 21's birthday nivi! </span>
                <Typist.Delay ms={1000} />
            </Typist>
        </span>
        
        </>
    )
  }
  
  const Timer = () => {
    return <Countdown
      date={1693333800000}
    //   date={1661663881000}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Content />;
        } else {
          // Render a countdown
          return <span className='App-content birthday-2022-countdown'>{days !== 0 ? days : ''} {getTextFor('days', days)} {hours !== 0 ? hours : ''} {getTextFor('hours', hours)} {minutes !== 0 ? minutes : ''} {getTextFor('minutes', minutes)} {seconds !== 0 ? seconds : ''} {getTextFor('seconds', seconds)}</span>;
        }
      }}
    />
  }

export default Timer