import React, { useEffect } from "react";
import './chat.css';

function ChatController() {
  const chats = [ {
    name: "ms1",
    msg: "Who are you?",
    delay: 1000,
    align: "right",
    showTime: true,
    time: "19:58"
  }]

  function chatItem() {
    return(
      <div>
        <li class='message-right'>
          <div class='sp-msg1'>
            <span class='spinme-right'>
              <div class='spinner'>
                <div class='bounce1'>
                </div>
                <div class='bounce2'>
                </div>
                <div class='bounce3'>
                </div>
              </div>
            </span>
          </div>
          <div class='messageinner-msg1'>
            <span class='message-text'>{'bleh'}</span>
          </div>
        </li>
      </div>
    )
 }
  
  const chatItems = []
  
  return (
    <div>
      <div class="chat">
      <div class="chat-container">
        <div class="chat-listcontainer">
  
          <ul class="chat-message-list">
            {chatItem}
          </ul>
  
        </div>
      </div>
    </div>
    </div> 
  );
}


export default ChatController;